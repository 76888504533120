import { SiX, SiInstagram, SiFacebook, SiGmail, SiTiktok, SiYoutube, SiWhatsapp } from "react-icons/si";
import Image from 'next/image';

import sm from '@/assets/img/sm-no-text.png';

type SocialMediaProps = {
    name: string;
    url: string;
    icon: JSX.Element | string;
    image?: string;
    active?: boolean;
    }[];

export const socialMedia: SocialMediaProps = [
    {
        name: 'SuaMusica',
        url: 'https://www.suamusica.com.br/eddyrusso',
        icon: <Image src={sm} alt="sm" width={20} height={20} />,
        active: true,
    },
    {
        name: 'Instagram',
        url: 'https://instagram.com/eddyrusso_',
        icon: <SiInstagram />,
        active: true,
    },
    {
        name: 'Facebook',
        url: 'https://facebook.com/eddyrussooficial',
        icon: <SiFacebook />,
        active: true,
    },
    {
        name: 'Twitter',
        url: 'https://twitter.com/eddyrusso_',
        icon: <SiX />,
        active: false,
    },
    {
        name: 'TikTok',
        url: 'https://tiktok.com/@eddyrussooficial',
        icon: <SiTiktok />,
        active: true,
    },
    {
        name: 'YouTube',
        url: 'https://youtube.com/eddyrusso',
        icon:  <SiYoutube />,
        active: true,
    },
    {
        name: 'E-mail',
        url: 'mailto: eddyrussooficial@gmail.com',
        icon: <SiGmail />,
        active: true,
    },
    {
        name: 'WhatsApp',
        url: 'https://wa.me/5544991833879?text=Olá%20Eddy%20Russo!%20Vi%20seu%20site%20e%20gostaria%20de%20conversar%20com%20você.',
        icon: <SiWhatsapp />,
        active: true,
    },
];
